<template>
  <!-- breadcrumb -->
  <div class="breadcrumb">
    <div class="container">
      <ul>
        <li><router-link to="/">Inicio</router-link></li>
        <li>Sobre el SINGE</li>
      </ul>
    </div>
  </div>

  <section>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <h1 class="mb-4 pb-4">Sobre el SINGE</h1>
          <p>
            El Sistema de Indicadores de Género (SINGE) es un esfuerzo de la
            Secretaría de las Mujeres para recopilar, analizar y difundir
            información estadística con perspectiva de género sobre la situación
            y posición de las mujeres en la Ciudad de México.
          </p>
          <p>
            Busca visibilizar información clave para la toma de decisiones sobre
            el avance en el cierre de brechas entre mujeres y hombres que
            persisten en distintos ámbitos de la vida pública y privada.
          </p>
          <p>
            El SINGE se presenta en una plataforma de fácil acceso y comprensión
            de los temas que se abordan. Contiene información actualizada,
            metadatos y a las fuentes de datos de cada indicadores.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
